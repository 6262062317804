<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'Rates'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span></v-btn
          >
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <!--Start Date-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateStart"
                        label="Start Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        @blur="date1 = $_parseDate(d_dateStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <!--County-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="countiesStore.countiesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="County"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_countyID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <!--Program-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="programsStore.programsValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Program"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_programID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                    @input="pullProgramOptions(fk_programID)"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="
                  (fk_programID && programsStore.optionsValueList.length > 0) ||
                    (fk_programID && fk_programOptionID)
                "
              >
                <!--Program Options-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="programsStore.optionsValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Program Option"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_programOptionID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row v-else dense>
                <text-display
                  :displayLabel="'Program Option'"
                  :displayString="'Select Program First'"
                />
              </v-row>

              <!--Age Category-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="ageCategoriesStore.ageCategoriesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Age Category"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_ageCategoryID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <!--Rating-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="ratingsStore.ratingsValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Rating"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_ratingID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <!--Facility Type-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.facilityTypes"
                    item-text="value"
                    item-value="id"
                    label="Facility Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_facilityType"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <rate-entry />
      <!--Duplicate Button-->
      <v-tooltip
        top
        :color="ratesStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="ratesStore.filters.selectedID.length > 0 ? openDuplicateDialog() : ''"
          >
            <v-icon
              small
              :color="ratesStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'"
            >
              fal fa-clone
            </v-icon>
          </v-btn>
        </template>
        <span>Duplicate Selection</span>
      </v-tooltip>
    </v-toolbar>

    <!--Duplicate Dialog-->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Duplicate Rate(s)?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDuplicateDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12" md="6">
                Select Rate Start Date.
                <!--Start Date-->
                <v-menu
                  :close-on-content-click="false"
                  v-model="menu2"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="massStartDate"
                      placeholder=" "
                      persistent-placeholder
                      prepend-icon="fal fa-calendar-alt"
                      required
                      readonly
                      @blur="date2 = $_parseDate(massStartDate)"
                    />
                  </template>
                  <v-date-picker
                    v-model="date2"
                    @input="menu2 = false"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            rounded
            color="primary"
            @click.native="duplicateRate()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Pagination-->
    <pagination
      :show="ratesStore.rates.data && ratesStore.rates.data.length > 0"
      :showTotal="true"
      :currentPage="ratesStore.rates.current_page"
      :lastPage="ratesStore.rates.last_page"
      :total="ratesStore.rates.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="ratesStore.rates.data && ratesStore.rates.data.length > 0"
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">Start Date</th>
              <th class="text-left grey--text text--darken-1">County</th>
              <th class="text-left grey--text text--darken-1">Facility Type</th>
              <th class="text-left grey--text text--darken-1">Rating</th>
              <th class="text-left grey--text text--darken-1">Age Category</th>
              <th class="text-left grey--text text--darken-1">Program</th>
              <th class="text-left grey--text text--darken-1">Program Option</th>
              <th class="text-left grey--text text--darken-1">BK</th>
              <th class="text-left grey--text text--darken-1">Rate</th>
              <th class="text-left grey--text text--darken-1">Status</th>
              <th class="text-left grey--text text--darken-1">
                <v-btn rounded @click="selectDeselectAll()" color="primary" class="py-0"
                  ><v-icon small left>fal fa-check-double</v-icon>All</v-btn
                >
              </th>
            </tr>
          </thead>
          <tbody class="clickable"
              v-for="(item, index) in ratesStore.rates.data"
              :key="item.id"
              @click="openEntry(item.id)" >
            <tr>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ $_formatDate(item.d_dateStart) }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ item.county.d_name }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ $_switchProviderType(item.f_facilityType) }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ item.rating ? item.rating.d_name : "" }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ item.age_category ? item.age_category.d_name : "" }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                <span v-if="item.program">{{ item.program.d_name }}</span>
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                <span v-if="item.program_options">{{ item.program_options.d_name }}</span>
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ $_yesNo(item.f_bk) }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ $_formatMoney(item.d_rate) }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                {{ $_activeStatus(item.f_status) }}
              </td>
              <td style="border:none" class="text-left light-blue--text text--darken-4">
                <v-checkbox
                  dense
                  :key="index"
                  v-model="selectedID"
                  :value="item.id"
                  color="primary"
                  class="pr-2"
                  @click.stop=""
                  multiple
                >
                </v-checkbox>
              </td>
            </tr>
            <tr>
              <td style="vertical-align: text-top; height:5px; border-bottom:solid thin #dae1e7"
                class="text-caption grey--text"
                colspan="11"
                >
                {{ item.created_at ? 'Created: ' + $_formatTimestamp(item.created_at) : 'Created in filemaker application' }} &#8226;
                {{ item.updated_at ? 'Modified: ' + $_formatTimestamp(item.updated_at) : ' Last modified in filemaker application' }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Rates Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import RateEntry from "@/components/RateEntry";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "ratesStore/getFilterField",
  mutationType: "ratesStore/updateFilterField"
});

export default {
  name: "Rates",
  mixins: [mixin],
  components: {
    TitleBar,
    RateEntry,
    Pagination,
    TextDisplay
  },
  computed: {
    ...mapState({
      ratesStore: state => state.ratesStore,
      valueListsStore: state => state.valueListsStore,
      countiesStore: state => state.countiesStore,
      programsStore: state => state.programsStore,
      ageCategoriesStore: state => state.ageCategoriesStore,
      ratingsStore: state => state.ratingsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields([
      "page",

      "d_dateStart",
      "fk_countyID",
      "fk_programID",
      "fk_programOptionID",
      "fk_ageCategoryID",
      "fk_ratingID",
      "f_facilityType",
      "date1",
      "selectedID"
    ])
  },
  data() {
    return {
      menu: false,
      menu2: false,
      massStartDate: '',
      date2: '',
      filterMenu: false,
      showDialog: false
    };
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    },
    date2() {
      this.massStartDate = this.$_formatDate(this.date2);
    }
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);

      /*clear checkbox selections*/
      this.$store.dispatch("ratesStore/clearSelected");

      await this.loadValueLists();
      /* Grab filters */
      const filters = this.$store.getters["ratesStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("ratesStore/fetch", filters);
      /* Set default mass assignment start date */
      this.date2 = this.$_dateOfNextMonth(1);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      /* Grab all related value lists on entry */
      await this.$store.dispatch("countiesStore/valueList");
      await this.$store.dispatch("programsStore/valueList");
      await this.$store.dispatch("ageCategoriesStore/valueList");
      /* Pull Payment Related List */
      const type = {
        type: 1
      };
      await this.$store.dispatch("ratingsStore/valueList", type);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    clearFilterColumns() {
      this.d_dateStart = "";
      this.fk_countyID = "";
      this.fk_programID = "";
      this.fk_programOptionID = "";
      this.fk_ageCategoryID = "";
      this.fk_ratingID = "";
      this.f_facilityType = "";
      this.date1 = "";
      this.page = 1;
    },

    showFiltered() {
      if (
        this.d_dateStart ||
        this.fk_countyID ||
        this.fk_programID ||
        this.fk_programOptionID ||
        this.fk_ageCategoryID ||
        this.fk_ratingID ||
        this.f_facilityType
      ) {
        return true;
      }
    },

    async pullProgramOptions(id) {
      this.fk_programOptionID = "";
      const data = {
        fk_programID: id
      };
      await this.$store.dispatch("programsStore/valueListOptions", data);
    },

    async openEntry(id) {
      await this.$store.dispatch("ratesStore/read", id);

      const rate = this.$store.getters["ratesStore/getRate"];
      const data = {
        fk_programID: rate.fk_programID
      };
      await this.$store.dispatch("programsStore/valueListOptions", data);

      await this.$store.dispatch("ratesStore/entry", id);
    },

    selectDeselectAll() {
      this.$store.dispatch("ratesStore/selectDeselectAll");
    },

    openDuplicateDialog() {
      this.showDialog = true;
    },

    closeDuplicateDialog() {
      this.showDialog = false;
    },

    async duplicateRate() {
      //retrieve all selected id's from the selectedID state
      const selected = this.$store.getters["ratesStore/getSelected"];
      const data = {
        items: selected,
        startDate: this.date2
      };
      await this.$store.dispatch("ratesStore/duplicateSelected", data);
      this.initialize();
      this.closeDuplicateDialog();
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
tbody:hover {
  background-color: #eeeeee;
}
</style>
