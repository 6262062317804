<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Rates</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Rates Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-row dense>
                    <!--Start Date-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateStart"
                            label="Start Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            @blur="date1 = $_parseDate(d_dateStart)"
                            required
                            :rules="rules.d_dateStart"
                          />
                        </template>
                        <v-date-picker
                          v-model="date1"
                          @input="menu = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--County-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="countiesStore.countiesValueList"
                        item-text="d_name"
                        item-value="id"
                        label="County"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_countyID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                        required
                        :rules="rules.fk_countyID"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Program-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.programsValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Program"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_programID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullProgramOptions(fk_programID)"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      (fk_programID && programsStore.optionsValueList.length > 0) ||
                        (fk_programID && fk_programOptionID)
                    "
                  >
                    <!--Program Options-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.optionsValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Program Option"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_programOptionID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-else dense>
                    <text-display
                      :displayLabel="'Program Option'"
                      :displayString="'Select Program First'"
                    />
                  </v-row>

                  <v-row dense>
                    <!--Facility Type-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.facilityTypes"
                        item-text="value"
                        item-value="id"
                        label="Facility Type"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_facilityType"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Age Category-->
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        :items="ageCategoriesStore.ageCategoriesValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Age Category"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_ageCategoryID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Rating-->
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        :items="ratingsStore.ratingsValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Rating"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_ratingID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--BK-->
                    <v-col cols="12" sm="12" md="3">
                      <v-autocomplete
                        :items="valueListsStore.yesNo"
                        label="BK"
                        placeholder=" "
                        persistent-placeholder
                        item-text="value"
                        item-value="id"
                        v-model="f_bk"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Rate-->
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="d_rate"
                        label="Rate"
                        placeholder=" "
                        persistent-placeholder
                        required
                        :rules="rules.d_rate"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12" md="4">
                      <v-autocomplete
                        :items="valueListsStore.activeStatus"
                        label="Status"
                        placeholder=" "
                        persistent-placeholder
                        item-text="value"
                        item-value="id"
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "ratesStore/getEntryField",
  mutationType: "ratesStore/updateEntryField"
});

export default {
  name: "RateEntry",
  mixins: [mixin],
  components: {
    TextDisplay
  },
  computed: {
    ...mapState({
      ratesStore: state => state.ratesStore,
      valueListsStore: state => state.valueListsStore,
      countiesStore: state => state.countiesStore,
      programsStore: state => state.programsStore,
      ageCategoriesStore: state => state.ageCategoriesStore,
      ratingsStore: state => state.ratingsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_countyID",
      "fk_programID",
      "fk_programOptionID",
      "fk_ageCategoryID",
      "fk_ratingID",
      "d_dateStart",
      "d_rate",
      "f_bk",
      "f_facilityType",
      "f_status",
      "f_show",
      "date1"
    ])
  },
  data() {
    return {
      menu: false,
      rules: {
        d_dateStart: [
          v => !!v || "Date is required",
          v => (v !== null && v.length >= 10) || "Date must be in MM/DD/YYYY format"
        ],
        fk_countyID: [v => !!v || "County is required"],
        d_rate: [v => !!v || "Rate is required"]
      }
    };
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("ratesStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["ratesStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("ratesStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("ratesStore/update", entry);
        }
        this.cancelEntry();
      }
    },

    async pullProgramOptions(id) {
      this.fk_programOptionID = "";
      const data = {
        fk_programID: id
      };
      await this.$store.dispatch("programsStore/valueListOptions", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
